import styled, { css } from 'styled-components';
import {
  Button,
  Heading,
  MyNintendoStoreStackedLogoIcon,
  ResponsivePicture as Picture,
} from '@nintendo-of-america/component-library';
import {
  Constrained,
  ContentRating as ContentRatingComponent,
} from '@local/components';

const ContentRatingMediaQuery = '@media (min-width: 623px)';

const BOX_SHADOW = '0 4px 16px 0 rgba(0, 0, 0, 0.1)';

export const Wrap = styled.div`
  position: relative;
`;

export const Grid = styled(Constrained)`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: ${({ theme }) => theme.spacing[32]};
  padding-top: ${({ theme }) => theme.spacing[16]};
  margin-bottom: ${({ theme }) => theme.spacing[8]};

  ${({ theme }) => theme.mediaQuery.tablet} {
    padding-top: ${({ theme }) => theme.spacing[32]};
    margin-bottom: ${({ theme }) => theme.spacing[32]};
  }
`;

export const StoreLogo = styled(MyNintendoStoreStackedLogoIcon)`
  display: none;

  ${({ theme }) => theme.mediaQuery.desktop} {
    display: block;
    grid-column: 1 / span 2;
    margin-top: ${({ theme }) => theme.spacing[32]};
    color: white;
    width: 100%;
    height: auto;
  }
`;

export const Main = styled.div`
  grid-column: 1 / span 6;

  ${({ theme }) => theme.mediaQuery.desktop} {
    grid-column: 3 / span 4;
  }
`;

export const ResponsivePicture = styled(Picture)`
  border-radius: ${({ theme }) => theme.borderRadius}
    ${({ theme }) => theme.borderRadius} 0 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 0 0 ${({ theme }) => theme.borderRadius}
    ${({ theme }) => theme.borderRadius};
  box-shadow: ${BOX_SHADOW};
  padding: ${({ theme }) => css`
    ${theme.spacing[16]} ${theme.spacing[8]}
  `};

  ${({ theme }) => theme.mediaQuery.tablet} {
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing[24]};
    justify-content: ${({ $hasContentRating }) =>
      $hasContentRating ? 'space-between' : 'center'};
    padding: ${({ theme }) => css`
      ${theme.spacing[16]} ${theme.spacing[32]}
    `};
  }
`;

export const TitleWithCta = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: ${({ theme }) => theme.spacing[8]};

  ${({ theme }) => theme.mediaQuery.tablet} {
    text-align: left;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing[24]};
    align-items: center;
  }
`;

export const ContentRating = styled(ContentRatingComponent)`
  display: none;
  ${ContentRatingMediaQuery} {
    display: block;
  }
`;

export const Title = styled(Heading).attrs(() => ({
  variant: 'h2',
}))`
  margin: 0;
`;

export const Cta = styled(Button)`
  width: 100%;

  ${({ theme }) => theme.mediaQuery.tablet} {
    width: auto;
  }
`;
