import React from 'react';
import { string, shape, arrayOf } from 'prop-types';
import { FancyBorder } from '@nintendo-of-america/component-library';

import * as S from './StoreHeroBg.styles';

const HeroBg = ({ bgImage }) => {
  return (
    <S.Bg>
      <S.BgRed>
        <FancyBorder height={16} position="bottom" type="awning-primary" />
      </S.BgRed>
      <S.BgCustom $bgImage={bgImage}>
        <FancyBorder
          flip={true}
          height={16}
          placement="inside"
          position="bottom"
          type="awning-white"
        />
      </S.BgCustom>
    </S.Bg>
  );
};

HeroBg.propTypes = {
  bgImage: shape({
    color: string,
    image: arrayOf(
      shape({
        url: string,
      })
    ),
  }),
};

export default HeroBg;
