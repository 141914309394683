import React from 'react';
import { Link } from '@nintendo-of-america/next';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import { arrayOf } from 'prop-types';
import { cloudinaryUrl } from '@local/lib/helpers';
import { Heading } from '@nintendo-of-america/component-library';

import { FeaturedItemsPropTypes } from '@local/lib/proptypes';
import * as S from './FeaturedItemsC.styles';

const FeaturedItemsC = ({ items }) => {
  const { text } = useLocalizer();
  return (
    <S.Section
      constrained={true}
      background={`url(${cloudinaryUrl({
        publicId: 'Dev/IS Page Placeholders/store/bg2.jpg',
        quality: '60',
      })})`}
    >
      <S.StyledHeadingCta
        ctaText="See all"
        ctaUrl="/store/characters/"
        headingProps={{ variant: 'h2' }}
      >
        {text('Shop by character')}
      </S.StyledHeadingCta>
      <Heading.NewLevel>
        <S.ScrollWrap>
          <S.Grid>
            {items.map((item, i) => {
              const { heading, assetPath, background, url } = item;
              return (
                <Link href={url} key={heading + i}>
                  <S.Character>
                    <S.CharacterName>{heading}</S.CharacterName>
                    <S.CharacterBg assetPath={background.primary.assetPath} />
                    <S.CharacterAsset assetPath={assetPath} />
                  </S.Character>
                </Link>
              );
            })}
          </S.Grid>
        </S.ScrollWrap>
      </Heading.NewLevel>
    </S.Section>
  );
};

FeaturedItemsC.propTypes = {
  items: arrayOf(FeaturedItemsPropTypes),
};

export default FeaturedItemsC;
