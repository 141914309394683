import styled, { css } from 'styled-components';
import { Text } from '@nintendo-of-america/component-library';

import { Constrained, LinkItem } from '@local/components';
import { TileLinkCss } from '@local/components/TileLink/TileLink.styles.js';

export const Grid = styled(Constrained)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: ${({ theme }) => theme.spacing[8]};
  padding-bottom: ${({ theme }) => theme.spacing[32]};

  ${({ theme }) => theme.mediaQuery.tablet} {
    grid-template-columns: repeat(3, 1fr);
    gap: ${({ theme }) => theme.spacing[20]};
    padding-bottom: ${({ theme }) => theme.spacing[48]};
  }

  @media (min-width: 1199px) {
    grid-template-columns: repeat(6, 1fr);
    gap: ${({ theme }) => theme.spacing[32]};
  }

  ${({ theme }) => theme.mediaQuery.desktopLarge} {
    grid-template-columns: repeat(6, 1fr);
  }
`;

export const iconCss = css`
  color: ${({ theme }) => theme.color.primary};
  flex-basis: 20px;
  flex-shrink: 0;
  width: 20px;

  ${({ theme }) => theme.mediaQuery.tabletSmall} {
    flex-basis: 32px;
    width: 32px;
  }

  ${({ theme }) => theme.mediaQuery.tablet} {
    flex-basis: 48px;
    width: 48px;
  }

  @media (min-width: 1199px) {
    flex-basis: 64px;
    width: 64px;
  }

  ${({ theme }) => theme.mediaQuery.desktopLarge} {
    width: 64px;
  }
`;

export const Item = styled(LinkItem)`
  ${TileLinkCss};
  gap: ${({ theme }) => theme.spacing[8]};
  justify-content: flex-start;
  padding: ${({ theme }) => theme.spacing[16]}
    ${({ theme }) => theme.spacing[12]};

  ${({ theme }) => theme.mediaQuery.tabletSmall} {
    text-align: left;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing[16]};
    padding: ${({ theme }) => theme.spacing[16]};
  }

  @media (min-width: 1199px) {
    padding-top: ${({ theme }) => theme.spacing[16]};
    padding-bottom: ${({ theme }) => theme.spacing[32]};
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

export const StyledText = styled(Text).attrs({
  variant: 'title',
})`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 0.875rem;
  line-height: 1.2;

  ${({ theme }) => theme.mediaQuery.tabletSmall} {
    font-size: 1rem;
  }

  ${({ theme }) => theme.mediaQuery.tablet} {
    font-size: 1.25rem;
  }
  ${({ theme }) => theme.mediaQuery.desktop} {
    text-align: center;
  }
`;
