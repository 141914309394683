import React from 'react';
import { shape, string } from 'prop-types';
import { Link as NextLink } from '@nintendo-of-america/next';
import { useAnalytics } from '@nintendo-of-america/react-hooks';
import { ScreenReaderOnly } from '@nintendo-of-america/component-library';
import { createGTMItemDetails, useMountEffect } from '@shared/util';

import SubNav from '../SubNav';
import HeroBg from './StoreHeroBg';
import * as S from './StoreHero.styles';
import { ContentRating } from '@local/components';

const Hero = ({ content, background }) => {
  const { trackViewPromotion, trackSelectPromotion } = useAnalytics();
  const { image, cta, heading, contentRating, contentDescriptors, gamesShown } =
    content;

  const gtmPromoItem = {
    name: heading,
    sku: 'none',
  };

  useMountEffect(() =>
    trackViewPromotion([gtmPromoItem], createGTMItemDetails({ name: heading }))
  );

  const StoreHeroAsset = () => (
    <S.ResponsivePicture
      primary={image.assetPath}
      alt={image.alt}
      desktopMargin={false}
    />
  );

  return (
    <S.Wrap>
      <HeroBg bgImage={background} />
      <S.Grid>
        <S.StoreLogo name="my-nintendo-store-stacked-logo" />
        <S.Main>
          {cta.url ? (
            <NextLink href={cta.url}>
              <a aria-label={image.alt || cta.label}>
                <StoreHeroAsset />
              </a>
            </NextLink>
          ) : (
            <StoreHeroAsset />
          )}
          <S.Content $hasContentRating={contentRating}>
            <S.TitleWithCta>
              <S.Title>
                <ScreenReaderOnly>{image.alt}: </ScreenReaderOnly>
                {heading}
              </S.Title>
              <S.Cta
                href={cta.url}
                onClick={() => trackSelectPromotion([gtmPromoItem])}
              >
                {cta.label}
              </S.Cta>
            </S.TitleWithCta>
            {contentRating != null && (
              <S.ContentRating
                rating={contentRating}
                descriptors={contentDescriptors}
                gamesShown={gamesShown}
              />
            )}
          </S.Content>
        </S.Main>
      </S.Grid>
      <SubNav />
    </S.Wrap>
  );
};

Hero.propTypes = {
  background: HeroBg.propTypes.bgImage,
  content: shape({
    cta: shape({
      label: string,
      url: string,
    }),
    image: shape({
      alt: string,
      assetPath: string,
    }),
    heading: string,
    contentDescriptors: ContentRating.propTypes.descriptors,
    contentRating: ContentRating.propTypes.rating,
  }),
};

export default Hero;
