import { arrayOf, string, shape } from 'prop-types';
import { bool } from 'yup';

export const BackgroundPropTypes = shape({
  name: string,
  primary: shape({
    assetPath: string,
    resourceType: string,
  }),
  color: string,
});

export const CTACollectionPropTypes = shape({
  heading: string,
  ctas: arrayOf(
    shape({
      label: string,
      url: string,
    })
  ),
});

export const PromoRichTextCTAPropTypes = shape({
  assetPath: string,
  assetPathAlt: string,
  background: shape({
    backgroundType: string,
    backgroundTiled: bool,
  }),
  ctaList: CTACollectionPropTypes,
  heading: string,
  type: string,
  modifiers: arrayOf(string),
});

export const FeaturedItemsPropTypes = shape({
  assetPath: string,
  background: BackgroundPropTypes,
  heading: string,
  url: string,
});

export const FilterSectionPropType = shape({
  title: string,
  collapsible: bool,
  attribute: string.isRequired,
  hidden: bool,
});

export const JoyConColorPropType = shape({
  sku: string,
  label: string,
  colorCode: string,
});
