import styled from 'styled-components';

export const Bg = styled.div`
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
`;

export const BgRed = styled.div`
  display: none;

  ${({ theme }) => theme.mediaQuery.tablet} {
    display: block;
    position: relative;
    width: 100%;
    background-color: ${({ theme }) => theme.color.primary};
    height: 46%;
  }
`;

export const BgCustom = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url('${({ $bgImage }) =>
    $bgImage.image ? $bgImage.image[0].url : null}');
  background-color: ${({ $bgImage }) =>
    $bgImage.color ? $bgImage.color : 'transparent'};
  background-size: cover;

  ${({ theme }) => theme.mediaQuery.tablet} {
    height: 40%;
  }

  ${({ theme }) => theme.mediaQuery.desktop} {
    height: 44%;
  }
`;
