import styled from 'styled-components';
import { Heading, Image } from '@nintendo-of-america/component-library';

import { Constrained, LinkItem, Hr } from '@local/components';

export const Wrap = styled(Constrained)`
  padding-bottom: ${({ theme }) => theme.spacing[32]};

  ${({ theme }) => theme.mediaQuery.tablet} {
    padding-bottom: ${({ theme }) => theme.spacing[48]};
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: ${({ theme }) => theme.spacing[24]};

  ${({ theme }) => theme.mediaQuery.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const FeaturedItem = styled(LinkItem)`
  text-align: center;
`;

export const FeaturedItemText = styled(Heading)`
  margin-top: ${({ theme }) => theme.spacing[8]};
  margin-right: ${({ theme }) => theme.spacing[16]};
  margin-bottom: 0;
  margin-left: ${({ theme }) => theme.spacing[16]};
  font-size: ${({ theme }) => theme.spacing[20]};
  font-weight: 700;
`;

export const FeaturedItemImage = styled(Image)`
  max-width: 200px;
  margin: 0 auto;

  ${({ theme }) => theme.mediaQuery.tabletSmall} {
    max-width: 340px;
  }

  ${({ theme }) => theme.mediaQuery.tablet} {
    max-width: 350px;
    max-height: 295.5px;

    img {
      object-fit: contain;
    }
  }
`;

export const TopDivider = styled(Hr).attrs(() => ({ constrained: true }))`
  display: none;

  ${({ theme }) => theme.mediaQuery.tablet} {
    display: block;
  }
`;
