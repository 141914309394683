import { useLocalizer } from '@nintendo-of-america/react-hooks';
import {
  DPadIcon,
  CharacterIcon,
  ControllerIcon,
  SalesTagIcon,
  StarPlayNintendoIcon,
  TShirtIcon,
} from '@nintendo-of-america/component-library';

import * as S from './SubNav.styles';

// Order of these categories should match the order in <GlobalNav />
const SubNav = () => {
  const { text } = useLocalizer();
  return (
    <S.Grid>
      <S.Item href="/store/games">
        <DPadIcon css={S.iconCss} />
        <S.StyledText>{text('Games')}</S.StyledText>
      </S.Item>
      <S.Item href="/store/hardware">
        <ControllerIcon css={S.iconCss} />
        <S.StyledText>{text('Hardware')}</S.StyledText>
      </S.Item>
      <S.Item href="/store/merchandise">
        <TShirtIcon css={S.iconCss} />
        <S.StyledText>{text('Merchandise')}</S.StyledText>
      </S.Item>
      <S.Item href="/store/exclusives">
        <StarPlayNintendoIcon css={S.iconCss} />
        <S.StyledText>{text('Store exclusives')}</S.StyledText>
      </S.Item>
      <S.Item href="/store/characters">
        <CharacterIcon css={S.iconCss} />
        <S.StyledText>{text('Characters')}</S.StyledText>
      </S.Item>
      <S.Item href="/store/sales-and-deals">
        <SalesTagIcon css={S.iconCss} />
        <S.StyledText>{text('Sales & deals')}</S.StyledText>
      </S.Item>
    </S.Grid>
  );
};

export default SubNav;
