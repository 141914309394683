import styled from 'styled-components';
import {
  HeadingCta,
  Heading,
  Image,
} from '@nintendo-of-america/component-library';
import { Section as LocalSection } from '@local/components';

export const Section = styled(LocalSection)`
  background-size: auto 100%;
  background-repeat: repeat-x;
`;

export const ScrollWrap = styled.div`
  max-width: 100%;
  overflow: auto;
`;

export const Grid = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing[16]};
  grid-template-columns: repeat(4, minmax(208px, 1fr));

  ${({ theme }) => theme.mediaQuery.tablet} {
    grid-template-columns: repeat(4, minmax(262px, 1fr));
    grid-gap: ${({ theme }) => theme.spacing[32]};
  }
`;

export const StyledHeadingCta = styled(HeadingCta)`
  margin-bottom: ${({ theme }) => `${theme.spacing[24]}`};
`;

export const Character = styled.a`
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 318px;
  text-align: center;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius};

  ${({ theme }) => theme.mediaQuery.tablet} {
    height: 400px;
  }
`;

export const CharacterBg = styled(Image)`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  transform: scale(1.15);
  transition: ${({ theme }) =>
    `transform ${theme.movement.duration} ${theme.movement.easing}`};

  ${Character}:hover & {
    transform: scale(1);
  }
`;

export const CharacterAsset = styled(Image)`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  transition: ${({ theme }) =>
    `transform ${theme.movement.duration} ${theme.movement.easing}`};

  ${Character}:hover & {
    transform: scale(1.15);
  }
`;

export const CharacterName = styled(Heading).attrs(() => ({
  variant: 'h3',
}))`
  display: inline-block;
  padding: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[20]}`};
  margin-top: ${({ theme }) => theme.spacing[20]};
  background-color: ${({ theme }) => theme.scrim.dark};
  border-radius: ${({ theme }) => theme.spacing[32]};
  color: ${({ theme }) => theme.color.white};
`;
