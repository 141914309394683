import React from 'react';
import { arrayOf } from 'prop-types';

import { FeaturedItemsPropTypes } from '@local/lib/proptypes';
import { LinkItem, Section } from '@local/components';
import * as S from './FeaturedItemsB.styles';

const FeaturedItemsB = ({ items }) => {
  return (
    <Section constrained={true}>
      <S.Wrap>
        {items.map((item, i) => {
          const { heading, assetPath, url } = item;
          return (
            <LinkItem href={url} key={heading + i}>
              {/* TODO: IS-1835 Allow image to show placeholder without breaking overflow x on page load */}
              <S.Asset assetPath={assetPath} showPlaceholder={false} />
              <S.Label>{heading}</S.Label>
            </LinkItem>
          );
        })}
      </S.Wrap>
    </Section>
  );
};

FeaturedItemsB.propTypes = {
  items: arrayOf(FeaturedItemsPropTypes),
};

export default FeaturedItemsB;
