import React from 'react';
import { FancyBorder } from '@nintendo-of-america/component-library';

import { Farm, Section } from '@local/components';
import {
  CTACollectionPropTypes,
  BackgroundPropTypes,
} from '@local/lib/proptypes';
import * as S from './CTACollection.styles';

const CTACollection = ({ collection, background }) => {
  const sectionBackground = background.image[0].url
    ? `url(${background.image[0].url})`
    : background.color;

  return (
    <Section background={sectionBackground}>
      <FancyBorder
        position="top"
        placement="inside"
        height={16}
        type="wave-white"
      />
      <S.Wrap>
        <S.StyledHeading variant="h1">{collection.heading}</S.StyledHeading>
        <Farm items={collection.ctas} />
      </S.Wrap>
      <FancyBorder
        position="bottom"
        placement="inside"
        height={16}
        type="wave-white"
        flip={true}
      />
    </Section>
  );
};

CTACollection.propTypes = {
  collection: CTACollectionPropTypes,
  background: BackgroundPropTypes,
};

export default CTACollection;
