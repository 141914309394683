import React from 'react';
import { arrayOf } from 'prop-types';
import { useAnalytics } from '@nintendo-of-america/react-hooks';
import { useInViewEffect } from '@local/lib/hooks';
import { createGTMItemDetails } from '@shared/util';

import { FeaturedItemsPropTypes } from '@local/lib/proptypes';
import { Section } from '@local/components';
import * as S from './FeaturedItemsA.styles';

const FeaturedItem = ({ url, heading, assetPath }) => {
  const { trackViewPromotion, trackSelectPromotion } = useAnalytics();
  const { ref: trackingRef } = useInViewEffect(() =>
    trackViewPromotion(
      [
        {
          name: heading,
          sku: 'none',
        },
      ],
      createGTMItemDetails({ name: heading })
    )
  );

  return (
    <S.FeaturedItem
      ref={trackingRef}
      href={url}
      onClick={() => trackSelectPromotion([{ name: heading, sku: 'none' }])}
    >
      <S.FeaturedItemImage assetPath={assetPath} />
      <S.FeaturedItemText>{heading}</S.FeaturedItemText>
    </S.FeaturedItem>
  );
};

const FeaturedItemsA = ({ items }) => (
  <>
    <S.TopDivider />
    <Section constrained={true} divider={['bottom']}>
      <S.Row>
        {items.map((item, i) => {
          return <FeaturedItem key={i} {...item} />;
        })}
      </S.Row>
    </Section>
  </>
);

FeaturedItemsA.propTypes = {
  items: arrayOf(FeaturedItemsPropTypes),
};

export default FeaturedItemsA;
