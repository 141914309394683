import styled from 'styled-components';
import { Heading, Image } from '@nintendo-of-america/component-library';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[32]};
  text-align: center;

  ${({ theme }) => theme.mediaQuery.tablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Asset = styled(Image)`
  border-radius: ${({ theme }) => theme.borderRadius};
  overflow: hidden;
`;

export const Label = styled(Heading).attrs(() => ({
  variant: 'h1',
  as: 'h2',
  large: false,
}))`
  margin-top: ${({ theme }) => theme.spacing[12]};
  margin-bottom: 0;
`;
