import React, { useCallback } from 'react';
import { bool, node } from 'prop-types';
import { StoreNotification, PreviewModeBanner } from '@local/components';
import { useRestoreScroll } from '@local/lib/hooks';

const StoreWrap = ({ children, preview, disableScrollRestoration }) => {
  const { waitForElementRef } = useRestoreScroll();

  const handleContentMounted = useCallback(
    (node) => {
      if (node && !disableScrollRestoration) {
        waitForElementRef(node);
      }
    },
    [disableScrollRestoration, waitForElementRef]
  );

  return (
    <>
      <PreviewModeBanner preview={preview} />
      <StoreNotification />
      {children}
      <span ref={handleContentMounted} />
    </>
  );
};

StoreWrap.propTypes = {
  /** Whether to show preview data from Contentful */
  preview: bool,
  /** Whether to disable automatic scroll restoration on load */
  disableScrollRestoration: bool,
  children: node,
};

export default StoreWrap;
